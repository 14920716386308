'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:DispatchUnitStatus

 # @description

###
angular
  .module 'lpaData'
  .factory 'DispatchUnitStatus', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/dispatch/units/statuses')
  ]
